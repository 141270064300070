import { cx } from "@emotion/css";
import { FormControl, FormHelperText } from "@mui/material";
import { PropTypes } from "prop-types";
import styles from "./styles";

const InputLayout = ({ className, label, error, helper, children, required }) => (
  <div className={cx(className, "d-flex flex-column")}>
    {label && (
      <div className={cx(styles.label, "text-fs-12 pb-3")}>
        {label} {required && <span className="text-color-error">*</span>}
      </div>
    )}

    <FormControl error={!!error}>
      {children}

      <FormHelperText className={cx(styles.helperText, "position-absolute")}>
        <span className="text-fs-10">{error || helper}</span>
      </FormHelperText>
    </FormControl>
  </div>
);

InputLayout.defaultProps = {
  className: "",
  label: "",
  error: "",
  helper: "",
  required: false,
};

InputLayout.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  error: PropTypes.string,
  helper: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
};

export default InputLayout;
