import { cx } from "@emotion/css";
import PropTypes from "prop-types";
import styles from "./styles";

const FlatBoxLayout = ({ className, children, color, ...restProps }) => (
  <div
    className={cx(styles.layout, className, "d-flex p-3 w-100 text-fs-10 border-rounded", `bg-color-${color}`)}
    {...restProps}
  >
    {children}
  </div>
);

FlatBoxLayout.defaultProps = {
  className: null,
  color: "grey100",
};

FlatBoxLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["primary", "grey100", "warning"]),
};

export default FlatBoxLayout;
