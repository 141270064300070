import { cx } from "@emotion/css";
import LocationIcon from "@mui/icons-material/LocationOnOutlined";
import StarIcon from "@mui/icons-material/StarBorderRounded";
import { Autocomplete as MuiAutocomplete, TextField } from "@mui/material";
import { useField } from "formik";
import useOpenClose from "hooks/useOpenClose";
import InputLayout from "library/Layouts/InputLayout/DefaultInputLayout";
import { isEmpty } from "lodash";
import { PropTypes } from "prop-types";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import AutocompleteOptionType from "types/helperTypes/Autocomplete";

const Autocomplete = forwardRef(
  (
    {
      label,
      name,
      className,
      dropdownClassName,
      placeholder,
      options,
      onChange,
      onSelect,
      onReset,
      loading,
      required,
      ...restProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    const [{ value, ...field } = {}, { error } = {}] = useField(name);

    const [currValue, setCurrValue] = useState("");
    const [inputValue, setInputValue] = useState("");

    const [isDropdownOpen, openDropdown, closeDropdown] = useOpenClose(false);

    const onInputChange = useCallback(
      (_, newInputValue, reason) => {
        if (reason === "reset" || reason === "input") {
          setInputValue(newInputValue);
          reason === "input" && onChange(newInputValue);
          return;
        }

        setCurrValue("");
        setInputValue("");
        onReset();
      },
      [onChange, onReset]
    );

    const onSelectOption = useCallback(
      (_, newValue) => {
        if (!newValue) return;
        setCurrValue(newValue);
        onSelect(newValue.id);
      },
      [onSelect]
    );

    useEffect(() => {
      if (value) return;
      setCurrValue("");
      setInputValue("");
    }, [value]);

    useImperativeHandle(ref, () => ({
      closeDropdown,
    }));

    return (
      <InputLayout label={label} className={cx(className, "text-fw-semibold")} error={t(error)} required={required}>
        <MuiAutocomplete
          {...field}
          open={isDropdownOpen}
          componentsProps={{
            popper: { className: dropdownClassName },
          }}
          value={currValue}
          inputValue={inputValue || value}
          onChange={onSelectOption}
          onInputChange={onInputChange}
          onOpen={openDropdown}
          onClose={closeDropdown}
          options={options}
          renderInput={(params) => {
            const newParams = { ...params, inputProps: { ...params.inputProps, ...restProps } };
            return <TextField {...newParams} placeholder={placeholder} error={!!error} />;
          }}
          renderOption={(props, option) => (
            <div className="d-flex w-100" {...props}>
              <span>
                {option.isDefault ? (
                  <StarIcon color="primary" fontSize="small" />
                ) : (
                  <LocationIcon color="primary" fontSize="small" />
                )}
              </span>
              <span className="ml-3 text-truncate">{option.label}</span>
            </div>
          )}
          filterOptions={(_options) => _options}
          disableClearable={isEmpty(inputValue) && isEmpty(value)}
          loading={loading}
          disablePortal
          openOnFocus
          blurOnSelect
        />
      </InputLayout>
    );
  }
);

Autocomplete.defaultProps = {
  label: "",
  name: "",
  className: null,
  dropdownClassName: null,
  placeholder: "",
  onReset: () => {},
  loading: false,
  required: false,
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(AutocompleteOptionType).isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  loading: PropTypes.bool,
  required: PropTypes.bool,
};

export default Autocomplete;
