import { cx } from "@emotion/css";
import {
  KeyboardArrowDownRounded as DownIcon,
  AlarmOutlined as EtaIcon,
  KeyboardArrowUpRounded as UpIcon,
} from "@mui/icons-material";
import { addSeconds } from "date-fns";
import useDeviceType from "hooks/useDeviceType";
import useOpenClose from "hooks/useOpenClose";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import TextButton from "library/Atoms/Button/TextButton";
import { RiderIcon } from "library/Atoms/SVGIcons";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import DropDetailsLayout from "pages/Dashboard/layouts/DropDetailsLayout/DropDetailsLayout";
import ProofOfDeliveryModal from "pages/Dashboard/modals/ProofOfDeliveryModal/ProofOfDeliveryModal";
import { checkActiveOrderStatus } from "pages/Dashboard/utils/helper";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import DropOffType from "types/models/DropOff";
import PickUpType from "types/models/PickUp";
import RiderType from "types/models/Rider";
import { formatDistance } from "utility/date-fns";
import { prettifyDropReference, prettifyPackagesAmount } from "utility/helpers/orders";
import { CLAIMED_STATUS, DELIVERED_STATUS } from "../../../../../constants";
import StatusBanner from "../StatusBanner/StatusBanner";
import styles from "./DropDetails.styles";
import ProofOfDelivery from "./ProofOfDelivery/ProofOfDelivery";

const DropDetails = ({ pick_up, drop, rider }) => {
  const { t } = useTranslation();

  const { isSmallMobile } = useDeviceType();

  const [isDetailsOpen, openDetails, closeDetails] = useOpenClose(false);
  const [isProofOfDeliveryModalOpen, openProofOfDeliveryModal, closeProofOfDeliveryModal] = useOpenClose(false);

  const dropReference = useMemo(() => prettifyDropReference(drop.reference), [drop.reference]);

  const pickUpEta = useMemo(() => {
    if (pick_up.status !== CLAIMED_STATUS || !pick_up.eta) return null;
    return formatDistance(new Date(), addSeconds(new Date(), pick_up.eta));
  }, [pick_up.status, pick_up.eta]);

  const dropEta = useMemo(() => {
    if (drop.status !== CLAIMED_STATUS || !drop.eta) return null;
    return formatDistance(new Date(), addSeconds(new Date(), drop.eta));
  }, [drop.status, drop.eta]);

  const etaContext = useMemo(() => {
    if (pickUpEta && dropEta) return "pickUpAndDrop";
    if (pickUpEta && !dropEta) return "pickUp";
    if (!pickUpEta && dropEta) return "drop";
    return "";
  }, [pickUpEta, dropEta]);

  const parcelCustomerIds = useMemo(() => {
    return drop.packages.map(({ parcelCustomerId }) => parcelCustomerId).join(", ");
  }, [drop.packages]);

  return (
    <DropDetailsLayout
      dropReference={dropReference}
      titleSection={
        <>
          {dropReference} {parcelCustomerIds ? `- ${parcelCustomerIds}` : ""}
        </>
      }
      toggleButtonSection={
        <TextButton
          id={`toggle-drop-${dropReference}-details`}
          className={cx(styles.moreBtn, "d-flex flex-nowrap align-items-center text-fw-semibold")}
          contentClassName="text-color-primary text-nowrap text-fs-12 text-fw-semibold"
          label={!isSmallMobile && (isDetailsOpen ? t("common.buttons.showLessBtn") : t("common.buttons.showMoreBtn"))}
          onClick={isDetailsOpen ? closeDetails : openDetails}
          endIcon={
            isDetailsOpen ? <UpIcon className="pt-1" color="primary" /> : <DownIcon className="pt-1" color="primary" />
          }
        />
      }
      statusSection={<StatusBanner id={dropReference} status={drop.status} isDrop />}
      riderSection={
        <div className="d-flex flex-column mt-3">
          <div className="d-flex flex-nowrap align-items-center col-12 pt-3 px-4">
            <RiderIcon />
            <span className="pl-2 text-fw-semibold">{rider.name || "N/A"}</span>
            <span className="pl-2 text-color-primary text-fw-semibold">{rider.phone}</span>
          </div>

          {(pickUpEta || dropEta) && (
            <div className="d-flex flex-nowrap align-items-center col-12 pt-3 px-4">
              <EtaIcon className="mr-1" color="info" />
              <span className="pl-2 text-fw-semibold text-truncate">
                {t("dashboard.order.drop.eta", { context: etaContext, pickUpEta, dropEta })}
              </span>
            </div>
          )}
        </div>
      }
      packageDetailsSection={
        <>
          <MiscDetailsLayout
            className="col-12"
            titleSection={t("common.entities.order.pickUpAddress")}
            contentSection={`${pick_up?.location || ""} | ${pick_up?.postcode || ""}`}
          />

          <MiscDetailsLayout
            className="col-12"
            titleSection={t("common.entities.order.dropOffAddress")}
            contentSection={`${drop.location || ""} | ${drop.postcode || ""}`}
          />

          {drop.recipient?.name && (
            <MiscDetailsLayout
              className="col-sm-4 col-6"
              titleSection={t("common.entities.order.recipientName")}
              contentSection={drop.recipient.name}
            />
          )}

          {drop.recipient?.phone && (
            <MiscDetailsLayout
              className="col-sm-4 col-6"
              titleSection={t("common.entities.order.recipientPhone")}
              contentSection={drop.recipient.phone}
            />
          )}

          {drop.recipient?.email && (
            <MiscDetailsLayout
              className="col-sm-4 col-6"
              titleSection={t("common.entities.order.recipientEmail")}
              contentSection={drop.recipient.email}
            />
          )}

          <MiscDetailsLayout
            className="col-sm-4 col-6"
            titleSection={t("common.entities.order.numberOfItems")}
            contentSection={prettifyPackagesAmount(drop.packages)}
          />

          {(drop.notes || drop.note) && (
            <MiscDetailsLayout
              className="col-12"
              titleSection={t("common.entities.order.dropNotes")}
              contentSection={drop.notes || drop.note}
            />
          )}
        </>
      }
      proofOfDeliverySection={
        DELIVERED_STATUS.includes(drop.status) &&
        drop.proof_of_delivery?.delivery_time && (
          <PrimaryButton
            id={`open-proof-of-${dropReference}-delivery-btn`}
            className={cx(styles.proofBtn, "mt-4 mx-4 px-2 w-100")}
            contentClassName="text-fs-12"
            label={t("dashboard.order.drop.proofOfDeliveryBtn")}
            onClick={openProofOfDeliveryModal}
          />
        )
      }
      priceSection={
        drop.price !== "" && (
          <div className="col-12 px-4 text-right text-fs-14 text-truncate text-fw-semibold">
            <span className="pr-2">{t("utils.pricing.price")}:</span>

            <Trans
              i18nKey="utils.pricing.excludingVAT"
              components={{
                price: <NumberFormat value={drop.price} displayType="text" thousandSeparator prefix={drop.currency} />,
              }}
            />
          </div>
        )
      }
      modals={
        isProofOfDeliveryModalOpen && (
          <ProofOfDeliveryModal dropReference={dropReference} onClose={closeProofOfDeliveryModal}>
            <ProofOfDelivery {...drop.proof_of_delivery} />
          </ProofOfDeliveryModal>
        )
      }
      isRiderOutsideCollapse={checkActiveOrderStatus(drop.status)}
      isDetailsOpen={isDetailsOpen}
    />
  );
};

DropDetails.defaultProps = {
  pick_up: {},
  drop: {},
  rider: {},
};

DropDetails.propTypes = {
  pick_up: PickUpType,
  drop: DropOffType,
  rider: RiderType,
};

export default DropDetails;
