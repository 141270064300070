import { parsePhoneNumber } from "awesome-phonenumber";
import { isAfter } from "date-fns";
import * as yup from "yup";

yup.addMethod(yup.string, "phone", function phone(errorMessage) {
  return this.test(`test-phone`, errorMessage, function testPhone(value) {
    const { path, createError } = this;
    if (!value) return true;
    return (value && parsePhoneNumber(value).isValid()) || createError({ path, message: errorMessage });
  });
});

yup.addMethod(yup.date, "actual", function phone(errorMessage) {
  return this.test(`test-actual`, errorMessage, function testPhone(value) {
    const { path, createError } = this;
    if (!value) return true;
    return isAfter(value, new Date()) || createError({ path, message: errorMessage });
  });
});
