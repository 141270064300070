import { cx } from "@emotion/css";
import { KeyboardArrowDownRounded as DownIcon, KeyboardArrowUpRounded as UpIcon } from "@mui/icons-material";
import useDeviceType from "hooks/useDeviceType";
import useOpenClose from "hooks/useOpenClose";
import TextButton from "library/Atoms/Button/TextButton";
import MiscDetailsLayout from "library/Layouts/DetailsLayout/MiscDetailsLayout/MiscDetailsLayout";
import DropDetailsLayout from "pages/Dashboard/layouts/DropDetailsLayout/DropDetailsLayout";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DropOffType from "types/models/DropOff";
import PickUpType from "types/models/PickUp";
import { prettifyDropReference, prettifyPackagesAmount } from "utility/helpers/orders";
import styles from "./DropDetails.styles";

const DropDetails = ({ pick_up, drop }) => {
  const { t } = useTranslation();
  const { isSmallMobile } = useDeviceType();

  const [isDetailsOpen, openDetails, closeDetails] = useOpenClose(false);

  const dropReference = useMemo(() => prettifyDropReference(drop.reference), [drop.reference]);

  const parcelCustomerIds = useMemo(() => {
    return drop.packages.map(({ parcelCustomerId }) => parcelCustomerId).join(", ");
  }, [drop.packages]);

  return (
    <DropDetailsLayout
      dropReference={dropReference}
      titleSection={
        <>
          {dropReference} {parcelCustomerIds || ""}
        </>
      }
      proofOfDeliverySection={
        <>
          <MiscDetailsLayout
            className="col-12"
            titleSection={t("common.entities.order.dropOffAddress")}
            contentSection={`${drop.location || ""} | ${drop.postcode || ""}`}
          />

          {(drop.notes || drop.note) && (
            <MiscDetailsLayout
              className="col-12"
              titleSection={t("common.entities.order.dropNotes")}
              contentSection={drop.notes || drop.note}
            />
          )}
        </>
      }
      toggleButtonSection={
        <TextButton
          id={`toggle-drop-${dropReference}-details`}
          className={cx(styles.moreBtn, "d-flex flex-nowrap align-items-center text-fw-semibold")}
          contentClassName="text-color-primary text-nowrap text-fs-12 text-fw-semibold"
          label={!isSmallMobile && (isDetailsOpen ? t("common.buttons.showLessBtn") : t("common.buttons.showMoreBtn"))}
          onClick={isDetailsOpen ? closeDetails : openDetails}
          endIcon={
            isDetailsOpen ? <UpIcon className="pt-1" color="primary" /> : <DownIcon className="pt-1" color="primary" />
          }
        />
      }
      packageDetailsSection={
        <>
          {drop.recipient?.name && (
            <MiscDetailsLayout
              className="col-sm-4 col-6"
              titleSection={t("common.entities.order.recipientName")}
              contentSection={drop.recipient.name}
            />
          )}

          {drop.recipient?.phone && (
            <MiscDetailsLayout
              className="col-sm-4 col-6"
              titleSection={t("common.entities.order.recipientPhone")}
              contentSection={drop.recipient.phone}
            />
          )}

          {drop.recipient?.email && (
            <MiscDetailsLayout
              className="col-sm-4 col-6"
              titleSection={t("common.entities.order.recipientEmail")}
              contentSection={drop.recipient.email}
            />
          )}

          <MiscDetailsLayout
            className="col-sm-4 col-6"
            titleSection={t("common.entities.order.numberOfItems")}
            contentSection={prettifyPackagesAmount(drop.packages)}
          />

          <MiscDetailsLayout
            className="col-12"
            titleSection={t("common.entities.order.pickUpAddress")}
            contentSection={`${pick_up?.location || ""} | ${pick_up?.postcode || ""}`}
          />

          {(pick_up.notes || pick_up.note) && (
            <MiscDetailsLayout
              className="col-12"
              titleSection={t("library.order.pickUpNotes")}
              contentSection={pick_up.notes || pick_up.note}
            />
          )}
        </>
      }
      isDetailsOpen={isDetailsOpen}
    />
  );
};

DropDetails.defaultProps = {
  pick_up: {},
  drop: {},
};

DropDetails.propTypes = {
  pick_up: PickUpType,
  drop: DropOffType,
};

export default DropDetails;
