import useDeviceType from "hooks/useDeviceType";
import { size } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import NewOrderDropDetailsLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderDropDetailsLayout/NewOrderDropDetailsLayout";
import NewOrderSummaryLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderSummaryLayout/NewOrderSummaryLayout";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { format } from "utility/date-fns";
import { prettifyPackagesAmount } from "utility/helpers/orders";
import { NEW_ORDER_FORM_STEPS, REPEAT_ORDER_TYPES } from "../../../../constants";
import MessageLayout from "../../layouts/MessageLayout/MessageLayout";
import IncreasedPriceMessage from "./IncreasedPriceMessage/IncreasedPriceMessage";
import styles from "./NewOrderDetails.styles";

const NewOrderDetails = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceType();

  const [
    {
      order: { startDate, endDate, pick_up, drops, isExpress, repeat_settings },
      price,
    },
    { step },
  ] = useNewOrderForm();

  const discount = useMemo(() => (Number(price.starting_price) - Number(price.price)).toFixed(2), [price]);

  const newOrderDate = useMemo(() => format(startDate, "MMM dd, yyyy"), [startDate]);
  const newOrderPickUpTime = useMemo(() => format(startDate, "HH:mm"), [startDate]);
  const newOrderDropOffTime = useMemo(() => format(endDate, "HH:mm"), [endDate]);

  const isLastStep = useMemo(() => step === NEW_ORDER_FORM_STEPS.BOOKING, [step]);

  return (
    <NewOrderSummaryLayout
      className={isMobile ? "order-first my-4" : styles.container(isLastStep)}
      dateSection={newOrderDate}
      pickUpSection={`${newOrderPickUpTime} - ${pick_up.location} | ${pick_up.postcode}`}
      pickUpNotesSection={pick_up.notes}
      dropsSection={drops.map(({ reference, location, postcode, packages, notes }, dindex) => (
        <NewOrderDropDetailsLayout
          key={reference}
          titleSection={`${t("newOrder.orderDetails.dropOff")} ${size(drops) > 1 ? `#${dindex + 1}` : ""}`}
          dropAddressSection={`${newOrderDropOffTime} - ${location} | ${postcode}`}
          packageTypeSection={prettifyPackagesAmount(packages)}
          notesSection={notes || null}
        />
      ))}
      priceSection={
        isLastStep && (
          <Trans
            i18nKey="utils.pricing.excludingVAT"
            context={discount > 0 ? "discount" : ""}
            components={{
              price: <NumberFormat value={price.price} displayType="text" thousandSeparator prefix={price.currency} />,
              del: <del />,
              discount: (
                <NumberFormat
                  value={price.starting_price}
                  displayType="text"
                  thousandSeparator
                  prefix={price.currency}
                />
              ),
            }}
          />
        )
      }
      repeatOrderSection={
        repeat_settings.type === REPEAT_ORDER_TYPES.EXACT_DATES &&
        repeat_settings.dates.map((dateToRepeat) => format(new Date(dateToRepeat), "MMM dd yyyy")).join(", ")
      }
      messagesSection={
        <>
          <IncreasedPriceMessage startDate={startDate} isExpress={isExpress} />

          {isLastStep && (
            <MessageLayout
              className="col-12 mt-3 px-0"
              labelSection={<>{t("newOrder.estimation.notFinalPriceMessage")}</>}
            />
          )}
        </>
      }
      data-cy="new-order-details"
    />
  );
};

export default NewOrderDetails;
