import { cx } from "@emotion/css";
import FlatBoxLayout from "library/Layouts/BoxLayout/FlatBoxLayout";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import NewOrderDetailLayout from "../NewOrderDetailLayout/NewOrderDetailLayout";

const NewOrderSummaryLayout = ({
  className,
  contentClassName,
  dateSection,
  pickUpSection,
  pickUpNotesSection,
  dropsSection,
  priceSection,
  repeatOrderSection,
  messagesSection,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx(className, "flex-wrap col-12 col-md-6 px-0 pl-md-4 pr-md-0")} {...restProps}>
      <FlatBoxLayout className={cx(contentClassName, "flex-wrap col-12")} color="grey100">
        {dateSection && (
          <NewOrderDetailLayout titleSection={t("newOrder.orderDetails.date")} contentSection={dateSection} />
        )}

        <NewOrderDetailLayout titleSection={t("newOrder.orderDetails.pickUp")} contentSection={pickUpSection} />

        {pickUpNotesSection && (
          <NewOrderDetailLayout titleSection={t("newOrder.orderDetails.note")} contentSection={pickUpNotesSection} />
        )}

        <div className="d-flex flex-row flex-wrap col-12 px-0">{dropsSection}</div>

        {priceSection && (
          <NewOrderDetailLayout titleSection={t("newOrder.estimation.estimatedCosts")} contentSection={priceSection} />
        )}

        {repeatOrderSection && (
          <NewOrderDetailLayout titleSection={t("newOrder.orderDetails.repeat")} contentSection={repeatOrderSection} />
        )}
      </FlatBoxLayout>

      {messagesSection}
    </div>
  );
};

NewOrderSummaryLayout.defaultProps = {
  className: null,
  contentClassName: null,
  dateSection: null,
  pickUpNotesSection: null,
  priceSection: null,
  repeatOrderSection: null,
  messagesSection: null,
};

NewOrderSummaryLayout.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  dateSection: PropTypes.node,
  pickUpSection: PropTypes.node.isRequired,
  pickUpNotesSection: PropTypes.node,
  dropsSection: PropTypes.node.isRequired,
  priceSection: PropTypes.node,
  repeatOrderSection: PropTypes.node,
  messagesSection: PropTypes.node,
};

export default NewOrderSummaryLayout;
