import { has, merge } from "lodash";
import { validateSchema } from "utility/helpers/general";
import { generateNewDrop } from "utility/helpers/orders";
import { DEFAULT_LOCATION_DATA, DEFAULT_REPEAT_ORDER_SETTINGS } from "../../../constants";
import {
  DropFormSchema,
  PickUpFormSchema,
  TimeFormSchema,
} from "../components/SetDeliveryForm/SetDeliveryForm.validation";

export const generateNewOrder = ({ firstName, lastName, phone, email }) => ({
  startDate: null,
  endDate: null,
  isExpress: false,
  pick_up: {
    ...DEFAULT_LOCATION_DATA,
    notes: "",
    recipient: {
      name: firstName && lastName ? `${firstName} ${lastName}` : DEFAULT_LOCATION_DATA.recipient.name,
      phone: phone ?? DEFAULT_LOCATION_DATA.recipient.phone,
      email: email ?? DEFAULT_LOCATION_DATA.recipient.email,
    },
  },
  drops: [generateNewDrop()],
  repeat_settings: DEFAULT_REPEAT_ORDER_SETTINGS,
});

export const validateSetDeliveryForm = async ({ values, touched }, validateOnMount) => {
  let errors = {};

  if (validateOnMount) {
    errors = merge(errors, await validateSchema(TimeFormSchema, values));
    errors = merge(errors, await validateSchema(PickUpFormSchema, values));
    errors = merge(errors, await validateSchema(DropFormSchema, values));
  } else {
    if (has(touched, ["startDate", "repeat_settings"])) {
      errors = merge(errors, await validateSchema(TimeFormSchema, values));
    }
    if (has(touched, ["pick_up"])) errors = merge(errors, await validateSchema(PickUpFormSchema, values));
    if (has(touched, ["drops"])) errors = merge(errors, await validateSchema(DropFormSchema, values));
  }

  return errors;
};
