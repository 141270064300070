import "utility/validation";
import * as yup from "yup";
import { REPEAT_ORDER_TYPES } from "../../../../constants";

export const TimeFormSchema = yup.object().shape({
  startDate: yup.date().nullable().actual("common.forms.pickUpTimeError"),
  repeat_settings: yup.object().shape({
    type: yup.string(),
    dates: yup.array().when("type", {
      is: REPEAT_ORDER_TYPES.EXACT_DATES,
      then: yup.array().min(1),
    }),
  }),
});

export const PickUpFormSchema = yup.object().shape({
  pick_up: yup.object().shape({
    location: yup.string().required("common.forms.locationRequiredError"),
    recipient: yup.object().shape({
      email: yup.string().email("common.forms.emailFormatError"),
      phone: yup.string().phone("common.forms.phoneInvalidError"),
    }),
    // recipient: yup.object().shape({
    //   name: yup.string().required("newOrder.forms.dropOffForm.recipientNameRequiredError"),
    //   email: yup.string().email("common.forms.emailFormatError").required("common.forms.emailRequiredError"),
    //   phone: yup.string().phone("common.forms.phoneInvalidError").required("common.forms.phoneRequiredError"),
    // }),
  }),
});

export const DropFormSchema = yup.object().shape({
  drops: yup.array().of(
    yup.object().shape({
      location: yup.string().required("common.forms.locationRequiredError"),
      recipient: yup.object().shape({
        email: yup.string().email("common.forms.emailFormatError"),
        phone: yup.string().phone("common.forms.phoneInvalidError"),
      }),
      // recipient: yup.object().shape({
      //   name: yup.string().required("newOrder.forms.dropOffForm.recipientNameRequiredError"),
      //   email: yup.string().email("common.forms.emailFormatError").required("common.forms.emailRequiredError"),
      //   phone: yup.string().phone("common.forms.phoneInvalidError").required("common.forms.phoneRequiredError"),
      // }),
    })
  ),
});
