import { FormikProvider, useFormik } from "formik";
import useDebounce from "hooks/useDebounce";
import useOpenClose from "hooks/useOpenClose";
import Autocomplete from "library/Atoms/Autocomplete";
import PrimaryButton from "library/Atoms/Button/PrimaryButton";
import ModalLayout from "library/Layouts/ModalLayout";
import { isEmpty, size } from "lodash";
import useNewOrderForm from "pages/NewOrder/hooks/useNewOrderForm";
import NewOrderDropDetailsLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderDropDetailsLayout/NewOrderDropDetailsLayout";
import NewOrderSummaryLayout from "pages/NewOrder/layouts/NewOrderDetailsLayout/NewOrderSummaryLayout/NewOrderSummaryLayout";
import ConfirmOverwriteOrderModal from "pages/NewOrder/modals/ConfirmOverwriteOrderModal/ConfirmOverwriteOrderModal";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { retrieveSavedOrders } from "store/features/library.store";
import { format } from "utility/date-fns";
import { prettifyPackagesAmount } from "utility/helpers/orders";
import IncreasedPriceMessage from "../NewOrderDetails/IncreasedPriceMessage/IncreasedPriceMessage";

const ImportOrderForm = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();

  const savedOrders = useSelector(retrieveSavedOrders);

  const [, { dirty: isNewOrderFormDirty }] = useNewOrderForm();

  const importOrderForm = useFormik({
    initialValues: { label: "" },
    onSubmit: (values) => {
      onSubmit(values);
      onClose();
    },
  });
  const {
    values: { label, pick_up, startDate, endDate, drops, isExpress },
    setValues,
    submitForm,
    handleReset,
  } = importOrderForm;

  const [suggestions, setSuggestions] = useState([]);

  const [isOverwriteModalOpen, openOverwriteModal, closeOverwtireModal] = useOpenClose(false);

  const autocompleteRef = useRef();

  const defaultOrdersToImport = useMemo(() => {
    return savedOrders.map(({ id, label: orderLabel, ...restProps }) => {
      return { id, label: orderLabel, data: { id, label: orderLabel, ...restProps }, isDefault: true };
    });
  }, [savedOrders]);

  const onSelect = useCallback(
    (id) => setValues(savedOrders.find(({ id: orderId }) => orderId === id)),
    [savedOrders, setValues]
  );

  const onSearch = useCallback(
    (searchTerm) => {
      setSuggestions(
        defaultOrdersToImport.filter(({ label: optionLabel }) =>
          optionLabel.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    },
    [defaultOrdersToImport]
  );
  const debouncedSearch = useDebounce(onSearch, 750);

  useEffect(() => {
    if (size(defaultOrdersToImport) === 0) return;
    setSuggestions(defaultOrdersToImport);
  }, [defaultOrdersToImport]);

  useEffect(() => {
    if (!autocompleteRef.current.closeDropdown) return () => {};
    const { closeDropdown } = autocompleteRef.current;
    const scroll = document.querySelector("#import-order-form-scroll");
    scroll.addEventListener("scroll", closeDropdown);
    return () => scroll.removeEventListener("scroll", closeDropdown);
  }, [handleReset]);

  return (
    <FormikProvider value={importOrderForm}>
      <ModalLayout
        id="import-order-form-scroll"
        contentClassName="pb-md-4 pb-3 importScrollContainer"
        contentSection={
          <>
            <p className="mb-4">{t("newOrder.forms.importForm.formDescription")}</p>

            <div className="d-flex flex-wrap">
              <Autocomplete
                ref={autocompleteRef}
                id="library-autocomplete"
                name="label"
                className="col-md-6 col-12 px-0"
                dropdownClassName="position-fixed"
                label={t("newOrder.forms.importForm.importLabel")}
                placeholder={t("newOrder.forms.importForm.importPlaceholder")}
                options={suggestions}
                onChange={debouncedSearch}
                onSelect={onSelect}
                onReset={handleReset}
              />

              {label && (
                <NewOrderSummaryLayout
                  className="mt-4 pt-md-3"
                  contentClassName="mt-1"
                  pickUpSection={`${format(new Date(startDate), "HH:mm")} - ${pick_up.location} | ${pick_up.postcode}`}
                  pickUpNotesSection={pick_up.notes}
                  dropsSection={drops.map(({ reference, location, postcode, packages, notes }, dindex) => (
                    <NewOrderDropDetailsLayout
                      key={reference}
                      titleSection={`${t("newOrder.orderDetails.dropOff")} ${size(drops) > 1 ? `#${dindex + 1}` : ""}`}
                      dropAddressSection={`${format(new Date(endDate), "HH:mm")} - ${location} | ${postcode}`}
                      packageTypeSection={prettifyPackagesAmount(packages)}
                      notesSection={notes}
                    />
                  ))}
                  messagesSection={<IncreasedPriceMessage startDate={startDate} isExpress={isExpress} />}
                />
              )}
            </div>
          </>
        }
        modalControls={
          <PrimaryButton
            id="submit-order-import-btn"
            className="w-100"
            label={t("newOrder.modals.importOrderModal.submitBtn")}
            onClick={isNewOrderFormDirty ? openOverwriteModal : submitForm}
            disabled={isEmpty(label)}
          />
        }
        modals={
          isOverwriteModalOpen && <ConfirmOverwriteOrderModal onConfirm={submitForm} onClose={closeOverwtireModal} />
        }
      />
    </FormikProvider>
  );
};

ImportOrderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportOrderForm;
