import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSavedOrdersStatus } from "store/features/library.store";
import { fetchSavedOrdersRequest } from "store/requests/library.requests";
import useNewOrderForm from "./hooks/useNewOrderForm";

const withSavedOrders = (Component) => (props) => {
  const dispatch = useDispatch();

  const { idle: ordersIdleStatus } = useSelector(retrieveSavedOrdersStatus);

  const [, , { setOrderDetails }] = useNewOrderForm();

  const handleImportSavedOrder = useCallback(
    (values) => {
      setOrderDetails({ ...values, startDate: new Date(values.startDate), endDate: new Date(values.endDate) });
    },
    [setOrderDetails]
  );

  useEffect(() => {
    if (!ordersIdleStatus) return;
    dispatch(fetchSavedOrdersRequest());
  }, [ordersIdleStatus, dispatch]);

  return <Component {...props} handleImportSavedOrder={handleImportSavedOrder} />;
};

export default withSavedOrders;
