import { del, get, getPublic, post, postPublic, put } from "./api-helper";

const newOrderAPI = {
  createOrder: (order) => postPublic(`/quote`, order),
  calculateOrderPrice: ({ order, userId }) => post(`/pricing/calculatePrice/${userId}`, order),
};

const ordersAPI = {
  orders: ({ page, filters }) => get(`/orders/list/all`, { params: { page, ...filters } }),
  riderPosition: (id) => put(`/orders/${id}/location`),
};

const addressAPI = {
  addresses: () => get(`/library/addresses`),
  createAddress: (address) => post(`/library/address`, address),
  removeAddress: (id) => del(`/library/address/${id}`),
  updateAddress: (address) => put(`/library/address/${address.id}`, address),
};
const savedOrdersAPI = {
  orders: () => get(`/library/orders`),
  saveOrder: (order) => post(`/library/order/${order.id}`, order),
  removeOrder: (id) => del(`/library/order/${id}`, id),
  updateOrder: (order) => put(`/library/order/${order.id}`, order),
};
const libraryAPI = { ...addressAPI, ...savedOrdersAPI };

const contactAPI = {
  register: (partner) => postPublic(`/partner/new`, partner),
  contact: (id) => get(`/contacts/${id}`),
  updateContact: ({ contact, userId }) => put(`/contacts/${userId}`, contact),
  changeLocale: ({ id, locale }) => put(`/contacts/locale/${id}`, { locale }),
  reacceptTerms: (id) => put(`/contacts/${id}/terms`),
};

const localizationAPI = {
  getLocalizations: () => getPublic("/localizations"),
};

const invoicesAPI = {
  invoices: ({ page }) => get(`/invoices`, { params: { page } }),
  getDownloadLink: (id) => post(`/invoices/download/${id}`),
};

const PartnerApi = {
  ...newOrderAPI,
  ...ordersAPI,
  library: { ...libraryAPI },
  ...contactAPI,
  ...localizationAPI,
  ...invoicesAPI,
};

export default PartnerApi;
