import { getAuth, signOut } from "@firebase/auth";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { isUndefined } from "lodash";

/* PRIVATE API */
const axiosApi = axios.create({ baseURL: process.env.REACT_APP_PRIVATE_API_URL });

axiosApi.interceptors.request.use(
  async (config) => {
    const isTesting = !isUndefined(window.Cypress);

    let accessToken = null;
    let uid = null;
    /* For tests environment */
    if (isTesting) {
      const user = JSON.parse(localStorage.getItem("authUser"));

      accessToken = user.stsTokenManager.accessToken;
      uid = user.uid;
    } else {
      try {
        const auth = getAuth();
        accessToken = await auth.currentUser.getIdToken();
        uid = auth.currentUser.uid;
      } catch {
        signOut(getAuth());
      }
    }

    config.headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "x-user-uid": `${uid}`,
      "x-api-key": `95b28f23-edac-4ead-8aa4-6bf59938a388`,
      "timezone-offset": new Date().getTimezoneOffset(),
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) signOut(getAuth());
    else {
      const { data = {}, config = {}, headers = {} } = error.response || {};
      Sentry.withScope((score) => {
        let body = {};
        try {
          body = JSON.parse(config.data);
        } catch (e) {
          body = {};
        }

        score.setExtras({ data, body, headers });
        Sentry.captureException(error);
      });
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  const results = await axiosApi.get(url, { ...config }).then((response) => response.data);
  return results;
}

export async function post(url, data, config = {}) {
  const results = await axiosApi.post(url, { ...data }, { ...config }).then((response) => response.data);
  return results;
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url, config = {}) {
  const result = await axiosApi.delete(url, { ...config }).then((response) => response.data);
  return result;
}

/* PUBLIC API */
const axiosPublicApi = axios.create({ baseURL: process.env.REACT_APP_API_URL });

axiosPublicApi.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: "application/json",
      "x-api-key": `95b28f23-edac-4ead-8aa4-6bf59938a388`,
      "timezone-offset": new Date().getTimezoneOffset(),
    };
    return config;
  },
  (error) => {
    if (error.response?.status !== 401 && error.response?.status !== 403) {
      const { data = {}, config = {}, headers = {} } = error.response || {};
      Sentry.withScope((score) => {
        score.setExtras({ data, body: config.data ? JSON.parce(config.data) : {}, headers });
        Sentry.captureException(error);
      });
    }
    return Promise.reject(error);
  }
);

axiosPublicApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export async function getPublic(url, config = {}) {
  return axiosPublicApi.get(url, { ...config }).then((response) => response.data);
}

export async function postPublic(url, data, config = {}) {
  return axiosPublicApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function putPublic(url, data, config = {}) {
  return axiosPublicApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}
